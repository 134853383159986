import React from 'react'
import EmailIcon from '@material-ui/icons/Email';
import '../styles/Home.css';

function Home() {
  return (
    <div className='home'>
      <div className='about'>
        <h2> Hi, My Name is Teoh Jia Kang</h2>
        <div className="prompt">
          <p>A student who is willing to learn and trying out new stuffs. Free time are utilised 
            with learning activities such as self-taught programming, developing mini program and 
            project that run on Arduino and Raspberry Pi. Have hands-on experience with using, 
            building and modifying 3D Printer. Also worked as a part time robotic instructor and 
            lead teams to participate in competition. Using the knowledges and skills gained from 
            education and work to make life better with technology is my goal.</p>
          <EmailIcon />
        </div>
      </div>
      <div className='skills'> 
        <h1> Skills </h1>
        <ol className='list'>
        <li className='item'>
            <h2> Software </h2>
            <span> Solidwork, Autocad, Fusion360, Matlab, Simulink, PLECS, Quartes MPLAB, 
              MikroC Pro, PVsyst, Simplify3D, Cura, Prusa slicer, Lychee slicer, ChiTuBox, 
              Photon Workshop, Microsoft Access Database, Word, Spreadsheet, Adobe Photoshop, Adobe Premiere Pro   
              </span>
          </li>
          <li className='item'>
            <h2> Programming </h2>
            <span> Hardware description language(HDL), C/C++, Python, Visual Basic, JavaScript, HTML, CSS 

            </span>
          </li>
          <li className='item'>
            <h2> Hardware </h2>
            <span> 3D printer, Resin printer, CNC machine, Arduino, Raspberry Pi, STM32 </span>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default Home