import React from 'react'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import '../styles/Footer.css';
import { useNavigate } from 'react-router-dom'

function Footer() {
  const navigate = useNavigate();
  return (
    <div className ="footer">
        <div className='socialMedia' 
        onClick={() => {
          window.location.replace("https://www.facebook.com/teoh.jiakang")
        }}>
            <FacebookIcon />
        </div>
        <h2>Email: teohkang2000@gmail.com</h2>
        <p> &copy; 2023 alphakangaroo.com</p>
    </div>
  )
}

export default Footer