import React from 'react';
import { 
  VerticalTimeline, 
  VerticalTimelineElement, 
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from '@material-ui/icons/School';

function Experience() {
  return (
    <div className='experience'>
      <VerticalTimeline lineColor='#3e497a'>
        <VerticalTimelineElement 
          className='vertical-timeline-element--education'
          date="2013 - 2017"
          iconStyle={{ background: '#3e497a', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">SMJK KEAT HWA II, Alor Setar, Kedah</h3>
          <h4 className="vertical-timeline-element-subtitle">Secondary School</h4>
          <p>
            Pure science stream with ICT as additional subject.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement 
          className='vertical-timeline-element--education'
          date="January 2019 - April 2021"
          iconStyle={{ background: '#3e497a', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">UCSI University, Cheras, Kuala Lumpur</h3>
          <h4 className="vertical-timeline-element-subtitle">Diploma In Electrical & Electronics Engineering</h4>
          <p>
            May 2019 - April 2021
            Vice President of Robotics and AI Club
          </p>
          <p>
            Work as a Robotics Instrutor In Seven Sense(Raspi Sekolah)
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement 
          className='vertical-timeline-element--education'
          date="May 2021 - April 2024"
          iconStyle={{ background: '#3e497a', color: '#fff' }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">UCSI University, Cheras, Kuala Lumpur</h3>
          <h4 className="vertical-timeline-element-subtitle">Degree In Electrical & Electronics Engineering</h4>
          <p>
            May 2021 - January 2022
            Vice President of Robotics and AI Club
          </p>
          <p>
            January 2022 - January 2023
            President of Robotics and AI Club
          </p>
          <p>
            January 2023 - January 2024
            Vice treasure of Robotics and AI Club
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}

export default Experience