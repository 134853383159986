import Proj1_1 from '../assets/Project1_1.jpg';
import Proj2_1 from '../assets/Project2_1.jpg';
import Proj3_1 from '../assets/Project3_1.jpg';
import Proj4_1 from '../assets/Project4_1.jpg';
import Proj5_1 from '../assets/Project5_1.jpg';
import Proj6_1 from '../assets/Project6_1.jpg';
import Proj7_1 from '../assets/Project7_1.jpg';
import Proj8_1 from '../assets/Project8_1.jpg';
import Proj9_1 from '../assets/Project9_1.jpg';
import Proj10_1 from '../assets/Project10_1.jpg';
import Proj11_1 from '../assets/Project11_1.jpg';
// import Proj12_1 from '../assets/Project12_1.jpg';
// import Proj13_1 from '../assets/Project13_1.jpg';
import Proj14_1 from '../assets/Project14_1.jpg';
// import Proj15_1 from '../assets/Project15_1.jpg';
// import Proj16_1 from '../assets/Project16_1.jpg';
import Proj17_1 from '../assets/Project17_1.jpg';
import Proj18_1 from '../assets/Project18_1.jpg';
import Proj19_1 from '../assets/Project19_1.jpg';
import Proj20_1 from '../assets/Project20_1.jpg';
import Proj21_1 from '../assets/Project21_1.jpg';
import Proj22_1 from '../assets/Project22_1.jpg';
import Proj23_1 from '../assets/Project23_1.jpg';
import Proj24_1 from '../assets/Project24_1.jpg';
import Proj25_1 from '../assets/Project25_1.jpg';
import Proj26_1 from '../assets/Project26_1.jpg';
import Proj27_1 from '../assets/Project27_1.jpg';
// import Proj28_1 from '../assets/Project28_1.jpg';
import Proj29_1 from '../assets/Project29_1.jpg';
// import Proj30_1 from '../assets/Project30_1.jpg';
// import Proj31_1 from '../assets/Project31_1.jpg';



export const ProjectList = [
    {
        name: "Adventurous robot based on Mystrom NXT",
        image: Proj1_1,
        year: 2015,
        description: "An adventurous robot built using Mystrom NXT platform. It is designed to perform various exciting tasks and explore its environment."
    },
    {
        name: "Solar powered mini car",
        image: Proj2_1,
        year: 2016,
        description: "A small car powered by solar energy. It harnesses the power of the sun to drive its motor and move around."
    },
    {
        name: "Solar powered mini boat",
        image: Proj3_1,
        year: 2016,
        description: "A small Boat powered by solar energy. It harnesses the power of the sun to drive its motor and move around."
    },
    {
        name: "Greeting Robot based on Mystorm NXT",
        image: Proj4_1,
        year: 2017,
        description: "A friendly robot designed to greet and interact with people. It utilizes the Mystorm NXT platform for its operation."
    },
    {
        name: "Restuarant system based on Visual Basic",
        image: Proj5_1,
        year: 2018,
        description: "The Restaurant System based on Visual Basic is a comprehensive software application that enables users to conveniently order food for pickup or delivery. The system offers a user-friendly interface where customers can choose to log in as a guest or create an account for personalized features. When users create an account, the system provides the ability to remember their preferences, such as spice level preferences (spicy or non-spicy) and portion size preferences (big or small). Additionally, the system stores the user's delivery address, ensuring a seamless ordering experience for future orders. The system also incorporates an admin account with advanced functionalities. The admin account allows restaurant staff to manage the menu by adding or removing items as needed. Furthermore, the admin account provides access to the customer database, allowing staff members to gain insights into customer preferences, order history, and other relevant information. Overall, the Restaurant System based on Visual Basic streamlines the ordering process for customers while providing personalized features and efficient management capabilities for the restaurant staff."
    },
    {
        name: "Sumo Robot",
        image: Proj6_1,
        year: 2018,
        description: "A robot built specifically for Sumo-style robot competitions. It is designed to push or force its opponent out of the competition ring."
    },
    {
        name: "DIY Aquaponics with Pipe and plastic cup",
        image: Proj7_1,
        year: 2019,
        description: "A DIY aquaponics system built using pipes and plastic cups. It demonstrates how to build a simple aquaponics system using readily available materials."
    },
    {
        name: "Drainage system rubbish cleaner",
        image: Proj8_1,
        year: 2019,
        description: "A drainage system cleaner that automatically removes rubbish. It utilizes a Raspberry Pi for control and monitoring."
    },
    {
        name: "Flyer DIY Flies catcher with plastic bottle",
        image: Proj9_1,
        year: 2019,
        description: "Flyer is a DIY flies catcher built using a plastic bottle. It demonstrates how to build a simple flies catcher using readily available materials.",
    },
    // {
    //     name: "Automatic retraction cloths hanger on rain detection based on raspberry pi",
    //     image: Proj8_1,
    //     year: 2019,
    //     description: "A smart clothes hanger system that automatically retracts clothes upon rain detection. It utilizes a Raspberry Pi for rain detection and control."
    // },
    // {
    //     name: "Air purifying with algae and HEPA filter based on raspberry pi",
    //     image: Proj9_1,
    //     year: 2019,
    //     description: "An air purifying system that combines the power of algae and a HEPA filter. It uses a Raspberry Pi for control and monitoring."
    // },
    {
        name: "Blind stick that can show braille word based on raspberry pi",
        image: Proj10_1,
        year: 2019,
        description: "A smart blind stick that can display braille words. It incorporates a Raspberry Pi to process text input and control the braille display."
    },
    {
        name: "Building 3D printer with a 3D printer",
        image: Proj11_1,
        year: 2019,
        description: "A 3D printer constructed using another 3D printer. It demonstrates the concept of self-replicating machines and additive manufacturing."
    },
    // {
    //     name: "Face recognition locker based on raspberry pi",
    //     image: Proj12_1,
    //     year: 2019,
    //     description: "A secure locker system that uses face recognition technology for access control. It employs a Raspberry Pi for face detection and recognition."
    // },
//     {
//         name: "Line following medic dispenser robot based on arduino",
//         image: Proj13,
//         year: 2020,
//         description: "A robot designed to follow lines and dispense medication. It utilizes an Arduino board for control and navigation."
//     },
    {
        name: "Shooting air soft robot car based on raspberry pi",
        image: Proj14_1,
        year: 2020,
        description: "A remote-controlled car equipped with an airsoft gun. It is controlled using a Raspberry Pi and provides an engaging shooting experience."
    },
//     {
//         name: "Robot car with ability of moving side way based on raspberry pi",
//         image: Proj15,
//         year: 2020,
//         description: "A versatile robot car capable of moving sideways. It utilizes a Raspberry Pi for control and navigation, providing unique maneuverability."
//     },
//     {
//         name: "Colour detection object sorting robot based on raspberry pi",
//         image: Proj16,
//         year: 2020,
//         description: "A robot designed to sort objects based on color detection. It incorporates a Raspberry Pi for image processing and decision-making."
//     },
    {
        name: "Smart home system based on arduino",
        image: Proj17_1,
        year: 2020,
        description: "A comprehensive smart home system built using Arduino. It offers automation and control for various home devices and functions."
    },
    {
        name: "Smart Transportation for Emergency Services",
        image: Proj18_1,
        year: 2020,
        description: "An intelligent transportation system specifically designed for emergency services. It optimizes response times and enhances emergency operations."
    },
    {
        name: "DIY Racing Drone",
        image: Proj19_1,
        year: 2021,
        description: "A racing drone built from scratch as a do-it-yourself (DIY) project. It provides an exciting and immersive experience in drone racing."
    },
    {
        name: "Two wheel balancing robot based on raspberry pi",
        image: Proj20_1,
        year: 2021,
        description: "A self-balancing robot that operates on two wheels. It incorporates a Raspberry Pi for control and balance adjustment."
    },
    {
        name: "Social Distancing hat",
        image: Proj21_1,
        year: 2021,
        description: "A wearable hat equipped with sensors to enforce social distancing. It alerts the wearer when someone comes within a specified distance."
    },
    {
        name: "Self Made Wooden table",
        image: Proj22_1,
        year: 2021,
        description: "A wooden table constructed from scratch as a personal project. It showcases woodworking skills and craftsmanship."
    },
    {
        name: "Self Made Wooden Clean Room with HEPA filter",
        image: Proj23_1,
        year: 2021,
        description: "A clean room built using wooden materials and equipped with a HEPA filter. It provides a controlled environment for sensitive operations."
    },
    {
        name: "Discord Bot with python",
        image: Proj24_1,
        year: 2022,
        description: "A bot developed using Python programming language for the Discord platform. It provides various functionalities and automation within Discord servers."
    },
    {
        name: "Smart Home system with raspberry pi",
        image: Proj25_1,
        year: 2022,
        description: "An advanced smart home system powered by a Raspberry Pi. It offers integration and control for multiple home devices and functions."
    },
    {
        name: "Analogue Magnetic Levitation",
        image: Proj26_1,
        year: 2022,
        description: "A project exploring analogue magnetic levitation principles. It demonstrates levitation of objects using magnetic fields and precise control."
    },
    {
        name: "Factory Colour Sorting With Robot Arm",
        image: Proj27_1,
        year: 2022,
        description: "A robot arm designed to sort objects based on color detection. It incorporates a Raspberry Pi for image processing and decision-making."
    },
    // {
    //     name: "NAS System for houses with raspberry pi",
    //     image: Proj27,
    //     year: 2022,
    //     description: "A Network-Attached Storage (NAS) system designed for home use, utilizing a Raspberry Pi. It provides centralized storage and data access within a home network."
    // },
//     {
//         name: "Smart TV with raspberry pi",
//         image: Proj28,
//         year: 2022,
//         description: "A smart TV system built using a Raspberry Pi. It enables streaming, multimedia playback, and access to online services on a traditional TV."
//     },
    {
        name: "CoreXY 3D Printer with raspberry pi",
        image: Proj29_1,
        year: 2022,
        description: "A CoreXY-style 3D printer controlled by a Raspberry Pi. It offers precise and efficient printing capabilities using the CoreXY mechanism."
    },
//     {
//         name: "Multi-colour 3D Printing Machine",
//         image: Proj30,
//         year: 2022,
//         description: "A 3D printing machine capable of printing objects with multiple colors. It provides the ability to create vibrant and visually appealing 3D prints."
//     },
//     {
//         name: "Controlled Environment Agriculture",
//         image: Proj31,
//         year: 2023,
//         description: "A system for controlled environment agriculture, enabling optimal growth conditions for plants. It ensures precise control of temperature, humidity, and lighting."
//     }
];





