import React from 'react';
import ProjectItem from '../components/ProjectItem';
import { ProjectList } from '../helpers/ProjectList';
import '../styles/Projects.css';

function Projects() {
  // Group projects by year
  const projectsByYear = {};
  let id = 0;

  ProjectList.forEach((project) => {
    const year = project.year;
    if (!projectsByYear[year]) {
      projectsByYear[year] = [];
    }
    projectsByYear[year].push({ ...project, id: id++ });
  });

  return (
    <div className='projects'>
      <h1>My Projects</h1>

      {/* Display projects by year */}
      {Object.entries(projectsByYear).map(([year, projects]) => (
        <div key={year}>
          <h2>{year}</h2>
          <div className='projectList'>
            {projects.map((project) => (
              <ProjectItem
                key={project.id}
                id={project.id}
                name={project.name}
                image={project.image}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Projects;
